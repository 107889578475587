section.hero-section {
    min-height:400px;
    height: 1050px;


    .icon {
        margin: 60px auto 0;
    }

    // .display-1 {
    //     letter-spacing: 0.15em;
    // }
    // .info {
    //     a {
    //         font-family: eb-garamond, serif;
    //         font-size: 28px;
    //         letter-spacing: .02em;
    //     }
    //     .brdr {
    //         border-right: 1px solid rgba(255,255,255,.2);
    //         height: 30px;
    //     }
    //     .social {
    //         font-size: 21px;
    //     }
    // }

    .btn {
        padding: 18px 40px;
    }

    .phone-col {
        svg:not([class*="angle-down"]),
        i:not([class*="angle-down"]) {
            display: none !important;
        }
        ul[id*="rblPhones"] a{
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }
        .phone-dropdown-menu ul, .address-dropdown-menu ul {
            padding: 0;
            margin: 0;
        }
        .phone-dropdown-menu ul a .row, 
        .address-dropdown-menu ul a .row {
            padding: .5rem .25rem .5rem .5rem;
            position: relative;
        }
        .phone-dropdown-menu ul a, 
        .address-dropdown-menu ul a {
            line-height: 1.4em;
            transition: 0.3s ease;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, 1rem, 0);
            display: block;
        }
    }

    .phone-dropdown-menu ul a, 
    .address-dropdown-menu ul a {
    .row {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 5%;
                bottom: 5%;
                left: 5%;
                right: 5%;
                transition: 0.3s ease;
                background-color: rgba(15,20,26,0.1) !important;
                opacity: 0;
            }
        }
        &:hover {
            .row {
                &:before {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 1;
                }
            }
        }
    }
    
    .phone-dropdown-menu,
    .address-dropdown-menu {
        transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
        display: block;
        opacity: 0;
        visibility: hidden;
        padding: 1rem * 0.5;
        border: none;
        background: transparent;
        margin-top: -1.5rem !important;
        max-width: 100% !important;
        min-width: 16rem !important;
        margin-right: -5px;
        box-shadow: none !important;
        left: -2% !important;

        span[class*="col"] {
            color: #242220 !important;
        }
        span.phone-number {
            color: $primary !important;
        }

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 5%;
        bottom: 25%;
        left: 25%;
        right: 25%;
        transition: 0.3s ease;
        border-radius: $border-radius;
        background-color: #f9f9f9!important;
        box-shadow: $box-shadow;
        opacity: 0;
    }

    &.show {
        opacity: 1;
        visibility: visible;

        &:before {
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 1;
        }

        ul {
        @for $i from 1 to 10 {
            a:nth-child(#{$i}) {
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, 0, 0);
            transition-delay: 0.2 + ($i * 0.025s);
            }
        }
        }
    }
    }

    .mod, .wrap {
        min-height:250px;
        height: 1050px;
    }
    
    @media screen and (max-width: 1600px) {
        & {
            height: 1050px;
        }
        .mod, .wrap {
            height: 1050px;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            height: 75vw;
        }
        .mod, .wrap {
            height: 75vw;
        }
    }
}