section.contact-form {
    min-height: 859px;
    padding: 150px 0;
    
    ::placeholder {
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        letter-spacing: 0.15em;
        color: #26282B;
    }

    .form-control {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 0.15em;
        color: #26282B;
    }

    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }

    textarea {
        min-height: 142px;
    }
    
    [id*="btnSend"] {
        max-width: 196px;
    }
    
    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 70px 0;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            padding: 50px 0;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-top: 100px;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin-bottom: 0;
            padding: 50px 0;
        }
    }
    @media screen and (max-width: 767px) {
        .qc-form-wrapper {
            padding: 50px 15px;
        }
    }
    &.no-services {
        @media screen and (min-width: 992px) {    
            & {
                padding-top: 300px !important;
            }
        }
        
        @media screen and (max-width: 991px) {   
            & {
                padding-top: 0px !important;
            } 
        }
        &.no-testimonials {
            padding-top: 30px !important;
        }
    }

}
