
section.services-section {
    min-height: 859px;
    padding: 255px 0 200px;

    h4 {
        font-family: pt-sans, sans-serif;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.15em;
        color: #ffffff;
        transition: 0.4s;
        text-transform: uppercase;
    }
    p {
        font-size: 14px;
        letter-spacing: 0.15em;
        font-weight: 700;
        transition: 0.4s;
        color: $primary;
        text-transform: uppercase;
    }
    a:hover {
        h4 {
            color: $primary;
        }

        .icon {
            transform: rotateY(360deg);
            transition: 0.9s;
        }

        p {
            color: #ffffff;
        }
    }
    [class*="btn"]:not(.no-fx), .hover-fx2 {
        transition: 0.3s ease !important;
    }
    [class*="btn"]:not(.no-fx):not([class*="owl"]):hover, .hover-fx2:not([class*="owl"]):hover {
        transform: translate3d(0, -2px, 0);
        // box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2);
    }
    .text-fx .mod {
        padding: 70px 0;
        min-height: 206px;
        background-color: #FFFFFF;
        -webkit-transition: background-color 0.35s;
        transition: background-color 0.35s;
    }
    .text-fx h5 {
        font-size: 22px;
        letter-spacing: 0;
        text-transform: capitalize;
        color: #415276;
        font-weight: 400;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(0,65%,0);
        transform: translate3d(0,65%,0);
    }
    .text-fx .mod::before,
    .text-fx p {
        font-size: 16px;
        font-weight: 700;
        color: rgba($color: #FFFFFF, $alpha: 0.66);
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    .text-fx:hover h5 {
        color: #FFFFFF;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
    .text-fx:hover .mod::before,
    .text-fx:hover p {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .35s;
        -moz-transition: all .35s;
        transition: all .35s;
    }
    .text-fx:hover .mod {
        background-color: #0AB2C7;
    }
    
}